<template>
  <div class="un">
    <img style="width: 400px; height: 350px"
      src="../assets/images/403.png" alt="">

  <div class="msg">
    您暂时还没有权限访问,试试重新<a href="javaScript:;" @click="onLogin">登录</a>
  </div>
  
  </div>
</template>
<script>
export default {
  name:'403',
  methods:{
    onLogin(){
      this.$router.push("/login");
      window.sessionStorage.clear();  
    }
  }
}
</script>
<style lang="less" scoped>
.un{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .msg{
    font-size: 26px;
    margin-top: 20px;
    a{
      color: #409EFF;
    }
  }
}
</style>